/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'editable/upload': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.5 13.617l3.461-3.71a1.221 1.221 0 011.7-.001l2.324 1.704 1.548-2.327a1.903 1.903 0 012.89-.331L15.5 9.967V2.719a.22.22 0 00-.22-.219H2.72a.22.22 0 00-.22.22v10.897zm8.7-1.117l-.442.658L9.2 15.5h5.958a.22.22 0 00.202-.219v-3.385l-1.965-1.853a.404.404 0 00-.613.07l-1.586 2.385.004.002zM2.72 17h12.56c.95 0 1.72-.77 1.72-1.72V2.72C17 1.77 16.23 1 15.28 1H2.72C1.77 1 1 1.77 1 2.72v12.56c0 .95.77 1.72 1.72 1.72zm.492-1.948l3.629-3.888 2.31 1.694-1.637 2.435H3.432a.22.22 0 01-.22-.22v-.021zm3.549-7.75a1.711 1.711 0 100-3.422 1.711 1.711 0 000 3.422z" _fill="#fff"/>'
  }
})
