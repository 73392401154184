/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'editable/submit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g opacity=".5"><path pid="0" d="M19.086 1.413h.001c1.524.592 2.474 1.735 3.05 2.757a7.958 7.958 0 01.715 1.73c.013.046.022.082.027.105l.006.024v.005h0l.002.007.002.007c.486 2.018.633 4.093.608 5.958h0v.013c.025 1.835-.147 3.912-.634 5.959h0l-.002.012h0l-.001.005-.006.025a6.328 6.328 0 01-.15.509 7.963 7.963 0 01-.592 1.325c-.575 1.023-1.526 2.165-3.05 2.758h0l-.004.001c-1.471.585-4.247.862-7.058.887-2.804-.025-5.586-.326-7.064-.889-1.523-.592-2.473-1.735-3.048-2.757a7.955 7.955 0 01-.715-1.729 3.857 3.857 0 01-.027-.105l-.006-.025v-.004h0-.001v-.001l-.002-.008-.002-.01c-.51-2.04-.657-4.113-.632-5.978h0v-.013c-.025-1.835.146-3.912.633-5.959h0l.003-.012h0v-.001l.001-.005.006-.024.027-.106c.025-.093.065-.232.123-.403.117-.344.306-.816.592-1.326.575-1.021 1.525-2.164 3.048-2.756C6.414.825 9.196.525 12 .5c2.805.025 5.586.326 7.086.913z" _fill="#A7A7B8" _stroke="#A7A7B8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.183 4.332a.982.982 0 00-1.39 0L9.086 8.039a.982.982 0 101.39 1.39l2.03-2.03v10.043H7.693a.983.983 0 000 1.965H12.6c.615 0 1.106-.122 1.435-.459.328-.335.437-.824.437-1.413V7.4l2.03 2.03a.982.982 0 101.389-1.39l-3.707-3.707zM9.275 9.24c.28.28.733.28 1.012 0l2.485-2.485-2.485 2.485a.716.716 0 01-1.012 0m-2.06 9.717a.713.713 0 00.478.183H12.6c1.178 0 1.605-.473 1.605-1.605V6.755v10.78c0 1.132-.427 1.605-1.605 1.605H7.693a.713.713 0 01-.478-.183zM16.69 9.24A.716.716 0 0017.7 8.228.716.716 0 1116.69 9.24zm-7.414 0l-.002-.002.002.002z" _fill="#fff"/></g>'
  }
})
